import axios from "axios";
import { BaseDBDataSource } from "./EntitiesDataSource";

import { Logger } from "../../utils/logger";
const logger = new Logger('UsersDataSource');

class UsersDataSourceClass extends BaseDBDataSource {
    constructor () {
        super(null, {}, true);
    }

    async getAll () {
        return await this.get(`${this.baseUrl}/users/`);
    }

    async getById (userId) {
        return await this.get(`${this.baseUrl}/users/${userId}`)
    }

    async updateById (userId, fields) {
        return await this.put(`${this.baseUrl}/users/${userId}`, fields)
    }

    async getRelationshipsById (userId) {
        return await this.get(`${this.baseUrl}/users/${userId}/relationships`)
    }

    async uploadProfilePicture (userId, image) {
        const secretUrl = await this.get(`${this.baseUrl}/users/${userId}/profile-picture`);
        if (!secretUrl) {
            logger.error('Could not get an URL to put the image.');
            return false;
        }
        const upload = await axios.put(secretUrl, image, 
            { headers: { 'Content-Type': 'multipart/form-data' } });
        if (upload.status !== 200) {
            Logger.error('Upload failed');
            return false;
        }
        const url = secretUrl.split('?')[0];
        return await this.put(`${this.baseUrl}/users/${userId}/profile-picture`, {url});
    }

    async approveProfilePicture (userId, image) {
        return await this.put(`${this.baseUrl}/users/${userId}/profile-picture-approval/`, image);
    }
}

export const UsersDataSource = new UsersDataSourceClass();