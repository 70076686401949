import axios from 'axios';

import { API_BASE_URL } from "../../env-vars";

import { AuthDataSource } from './AuthDataSource';

const baseUrl = API_BASE_URL;

export class BaseDBDataSource {
    
    constructor (baseUrl, baseOptions, authenticated) {
        this.baseUrl = baseUrl || BaseDBDataSource.defaultBaseUrl;
        this.baseOptions = baseOptions;
        
        if (authenticated) {
            AuthDataSource.subscribe(authJwt => {
                this.baseOptions = {
                    ...this.baseOptions,
                    headers: { Authorization: authJwt }
                }
            });
        }
    }

    static defaultBaseUrl = baseUrl;

    async get (url, options) {
        console.log(this.baseOptions)
        const res = await axios.get(url, {...this.baseOptions, ...options});
        if (res.status === 200)  
            return res.data.data;
        return null;
    }

    async put (url, body, options) {
        const res = await axios.put(url, body, {...this.baseOptions, ...options});
        if (res.status === 200)  
            return res.data && res.data.data || true;
        return null;
    }
}

