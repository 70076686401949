import React, { useEffect, useState } from "react";
import { GetUsersWithRelationships } from "../../domain/usecase/GetUsersWithRelationshipsUC";
import { UserItem } from "../components/UserItem";

import '../styles/usersPage.scss';

export function UsersPage() {

  const [users, setUsers] = useState([]);

  useEffect(() => { 
    async function getUsers () {
      const _users = await GetUsersWithRelationships();
      setUsers(_users); 
    }
    getUsers();
  }, []);

  return (
    <div>
      <h3>Users</h3>
      <div className="all-users-container">
        {users.filter(u => u.image).concat(...users.filter(u => !u.image))
          .map(u => <UserItem key={u.id} user={u} />)}
      </div>

      <br></br>
      <br></br>

    </div>
  );
}
