import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
  Link,
  useLocation,
} from "react-router-dom";

import "./App.css";
import "./scrollBar.scss";

import { LoginPage } from "./presentation/screens/LoginPage";
import { HomePage } from "./presentation/screens/HomePage";
import { useAuth, ProvideAuth } from "./presentation/utils/authContext";
import { FormPage } from "./presentation/screens/FormPage";
import { GamesPage } from "./presentation/screens/games/GamesPage";
import { UsersPage } from "./presentation/screens/UsersPage";
import { ConceptPage } from "./presentation/screens/ConceptPage";

//https://stackoverflow.com/questions/69864165/error-privateroute-is-not-a-route-component-all-component-children-of-rou

function App() {
  return (
    <ProvideAuth>
      <Router>
        <AppContent />
      </Router>
    </ProvideAuth>
  );
}

function AppContent() {
  const location = useLocation();
  console.log("path name: ", location.pathname);
  return (
    <>
      <header>
        <div className="appLogo"></div>
        <nav hidden={location.pathname === "/concept"}>
          <Link to="/">Home</Link>
          <Link to="/users">Users</Link>
          <Link to="/games">Games</Link>
          <Link to="/concept">Concept</Link>
        </nav>
      </header>
      <Routes>
        <Route path="/" element={<PrivateRoute />}>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/users" element={<UsersPage />} />
          <Route exact path="/games" element={<GamesPage />} />
          <Route exact path="/form" element={<FormPage />} />
        </Route>
        <Route exact path="/concept" element={<ConceptPage />} />
        <Route exact path="/login" element={<LoginPage />} />
      </Routes>
    </>
  );
}

function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();

  if (auth.loading) {
    return <h2>Loading...</h2>;
  }
  return auth.user ? <Outlet /> : <Navigate to="/login" />;
}

export default App;
