import { UsersRepository } from "../../data/repositories/UsersRepository";

export const GetUsers = async (filters) => {
    console.log('getting users');
    const users = await UsersRepository.getAll();
    if (filters) {
        return users.filter(u => {
            for (let key in filters) {
                if (u[key] !== filters[key])
                    return false;
            }
            return true;
        });
    }
    return users;
}