import React, { useEffect, useState } from "react";
import { GetUsers } from "../../domain/usecase/GetUsersUC";
import "../styles/homepage.scss";

export function HomePage() {

  const [users, setUsers] = useState([]);
  const [currUser, setCurrUser] = useState();

  useEffect(() => { 
    GetUsers().then(res => setUsers(res || [])); 
  }, []);

  return (
    <div>
      <h3>HomePage</h3>
      <div className="users-container">
        {users.map(u => <li key={u.id}>
          <div>{u.email}: 
            <br/>
            <img src={u.images? Object.values(u.images)[0] : u.image} width="200" height="200"/>
            <br/>{u.firstName} {u.lastName}
          </div>
        </li>
        )}
      </div>

    </div>
  );
}
