import { UsersDataSource } from "../sources/UsersDataSource";
import { RepoDictionary, RepoItem } from "./lib/repo-utils";

import { m2ms } from '../../utils/conversions'

class UsersRepositoryClass {

    constructor () {
        this.repo = {
            usersList: new RepoItem(async () => await UsersDataSource.getAll(), m2ms(5)),
            users: new RepoDictionary(async (id) => await UsersDataSource.getById(id), m2ms(5)),
            usersRelationships: new RepoDictionary(async (id) => await UsersDataSource.getRelationshipsById(id), m2ms(5))
        };
    }

    async getAll () {
        return await this.repo.usersList.get();
    }

    async getById (userId) {
        return await this.repo.users.get(userId);
    }

    async putUserImage (userId, image) {
       return await UsersDataSource.uploadProfilePicture(userId, image);
    }

    async getUserRelationships (userId) {
        return await this.repo.usersRelationships.get(userId);
    }

    async updateById (userId, fields) {
        return await UsersDataSource.updateUserById(userId, fields);
    }

    async approveProfilePicture (userId, image) {
        return await UsersDataSource.approveProfilePicture(userId, image);
    }


}

export const UsersRepository = new UsersRepositoryClass();