import React, { useState } from "react";

import { UploadUserImage } from "../../domain/usecase/users/UploadUserImageUC";

export function FormPage() {

  const [targetUser, setTargetUser] = useState('');
  const [selectedFile, setSelectedFile] = useState();
	const [isSelected, setIsSelected] = useState(false);

  const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		setIsSelected(true);
	};

  const handleSubmit = async (e) => {
    const res = await UploadUserImage(targetUser, selectedFile);
    console.log(res);
  }

  return (
    <div>
      <h3>Form</h3>

      <input type="text" onChange={(e) => setTargetUser(e.target.value)}></input>
      <input type="file" name="file" onChange={changeHandler} />
      {isSelected ? (
				<div>
					<p>Filename: {selectedFile.name}</p>
					<p>Filetype: {selectedFile.type}</p>
					<p>Size in bytes: {selectedFile.size}</p>
					<p>
						lastModifiedDate:{' '}
						{selectedFile.lastModifiedDate.toLocaleDateString()}
					</p>
				</div>
			) : (
				<p>Select a file to show details</p>
			)}
      <button onClick={handleSubmit}>Upload</button>
    </div>
  );
}
