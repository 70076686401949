import { React, useState, useEffect } from "react";
import './style.scss';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

export function GamesSection (props) {

    const columns = [
        { 
            field: 'weekId', 
            headerName: 'Week ID', 
            width: 100 },
        {
            field: 'id',
            headerName: 'ID',
            width: 140,
            editable: true,
        },
        {
            field: 'type',
            headerName: 'Type',
            width: 140,
            editable: true,
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 240,
            editable: true,
        },
        {
            field: 'status',
            headerName: 'Status',
            editable: true,
            width: 160
        }
        ];

    
    const rows = [
        { weekId: 1, type: 'quiz', id: '123abc', name: 'quiz bellissimo', status: 'fatto' },
        { weekId: 2, type: 'memory', id: '576efv', name: 'memory', status: 'fatto' },
        { weekId: 1, type: 'altro', id: '234qed', name: 'quiz brutto', status: 'non fatto' },
        { weekId: 2, type: 'quiz', id: '266rgg', name: 'quiz bello ma difficile', status: 'non fatto' },
        { weekId: 3, type: 'quiz', id: '234ads', name: 'quizzone', status: 'fatto' },
        ];
    

    const [games, setGames] = useState(rows);
    const [formVisible, setFormVisible] = useState(false);

    let newName = "";
    let newType = "";

    function addNewGame(name, type) {
        //add new game (not working!!!)
        setGames([...games,
        {
            weekId: 1,
            type: type,
            id: '123abc',
            name: name,
            status: 'nuovo'
        }]);

        setFormVisible(false);
    }

    
      return (
        <div>
        <h3>Games</h3>
        <div className="toolbar">
            <button className="primaryBtn" onClick={() => setFormVisible(true)}>New Game</button>
        </div>
        <Box sx={{ height: 440, width: '100%' }}>
          <DataGrid className="dataGrid"
            rows={games}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[4]}
            checkboxSelection
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
          />
        </Box>
        
        <div className={`overlay ${!formVisible? "hidden" : ''}`}>
            <div className="wrapper">
            <div className="form">
                <h3>Add new Game</h3>
                <div>
                    <label>Name:
                        <input type="text" name="name" onInput={(e) => newName = e.target.value}/>
                    </label>
                </div>
                <div>
                    <label>Type: 
                        <input type="text" name="type" onInput={(e) => newType = e.target.value}/>
                    </label>
                </div>
                <div>
                    <button className="primaryBtn" onClick={() => addNewGame(newName, newType)}>
                    Add New Game
                    </button>
                </div>
            </div>
            </div>
        </div>
        
        </div>

      );
}