export class RepoItem {

    constructor (getFn, expiration) {
        this.getFn = getFn;
        this.expiration = expiration || 0;
 
        this.fetching = false;

        this.data = null;
        this.lastUpdated = false;

        this.cb = [];
    }

    get (...args) {
        return new Promise((resolve, reject) => {
            if (this.data) {
                if (!this.expiration || this.lastUpdated + this.expiration > Date.now()) {
                    return resolve(this.data);
                }
                this.data = null;
            }
    
            this.cb.push(data => resolve(data));
            
            if (!this.fetching) {
                this.fetching = true;
                this.getFn(...args)
                    .then((res) => {
                        this.data = res
                        this.lastUpdated = Date.now();
                        this.cb.map(cb => cb(this.data));
                        this.cb = [];
                        this.fetching = false;
                    });
            }
        });
    }

}

export class RepoDictionary {

    constructor (getFn, expiration) {
        this.getFn = getFn;
        this.expiration = expiration;

        this.data = {};
    }

    get (key) {
        if (!this.data[key]) {
            this.data[key] = new RepoItem(async () => this.getFn(key), this.expiration);
        }
        return this.data[key].get();
    }

}