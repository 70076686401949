import React from "react";

import './style.scss';
import { ApproveProfilePicture } from '../../../domain/usecase/users/ApproveUserImageUC';

export function UserItem (props) {

    const approveUser = () => {console.log('approve')}
    const blockUser = () => {console.log('block')}

    const user = props.user;

    const approveProfilePicture = async (userId, image) => {
        const res = await ApproveProfilePicture(userId, image);
        console.log(res);
        alert('Sent!');
      }
    

    return (
        <div className="user-item">
            <div className="user-data">
                <div className="wrapper-data">
                    <div className="user-image">   
                        <img src={user.images? Object.values(user.images)[0] : 'https://www.padrepio.it/wp-content/uploads/2019/09/padre_pio_alta_ris.jpg'} 
                        width="200" height="200" alt={user.id}/>
                    </div>
                    <div className="wrapper-name">
                        <div className="user-id">{user.id}</div>
                        <div className="user-fullName">
                            {user.firstName + " " + user.lastName}
                        </div>
                        {user.status? 
                        <div className={"user-status " + user.status}>{user.status}</div> : ''
                        }
                    </div>
                </div>
                
            </div>

            <div className="image-approval">
                {user.image? 
                    <div className="temp-image">
                        <img src={user.image? user.image : 'https://www.padrepio.it/wp-content/uploads/2019/09/padre_pio_alta_ris.jpg'} 
                        width="200" height="200" alt="no pic :("/>
                    </div> 
                : ''
                }
            </div>

            <div className="user-actions">
                {user.image? 
                    <button className="approve" onClick={() => approveProfilePicture(user.id, user.image)}>Approve image</button> : ''}
                {user.status === 'waiting'? 
                    <button onClick={approveUser}>Approve user</button> :
                    <button onClick={blockUser}>Block user</button>}
            </div>
            <div className="user-relationships">
                <span>Relationships: </span>
                <div className="relationship-list">
                    <ul>
                        {user.relationships.map(r => 
                       <>
                       <li key={`${r.src}_${r.dest}`}>{r.dest} 
                            <div className="level">
                                lev: {r.intensity}
                            </div>
                        </li>
                        </>
                        )}
                    </ul>

                </div>
            </div>
        </div>
    );
}