import React, { useEffect, useState } from "react";
import './style.scss';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

export function WeeksSection (props) {

    const columns = [
    { 
        field: 'id', 
        headerName: 'ID', 
    },
    {
        field: 'name',
        headerName: 'Name',
    },
    {
        field: 'startDate',
        headerName: 'Start date',
    },
    {
        field: 'endDate',
        headerName: 'End Date',
    },
    {
        field: 'status',
        headerName: 'Status',
    }
    ];

const rows = [
  { id: 1, name: 'Settimana della Madonna', startDate: '2/01/2023', endDate: '8/01/2023', status: 'bellissima' },
  { id: 2, name: 'Seconda settimana', startDate: '9/01/2023', endDate: '15/01/2023', status: 'magnifica' },
  { id: 3, name: 'Terza Settimana della Madonna', startDate: '2/01/2023', endDate: '8/01/2023', status: 'bellissima' },
  { id: 4, name: 'Settimana', startDate: '9/01/2023', endDate: '15/01/2023', status: 'magnifica' },
  { id: 5, name: 'Altra settimana della Madonna', startDate: '2/01/2023', endDate: '8/01/2023', status: 'bellissima' },
  { id: 6, name: 'Seconda settimana', startDate: '9/01/2023', endDate: '15/01/2023', status: 'magnifica' },
  { id: 7, name: 'Settimana', startDate: '2/01/2023', endDate: '8/01/2023', status: 'bellissima' },
  { id: 8, name: 'Terza Settimana della Madonna', startDate: '9/01/2023', endDate: '15/01/2023', status: 'magnifica' },
  { id: 9, name: 'Terza settimana', startDate: '2/01/2023', endDate: '8/01/2023', status: 'bellissima' },
  { id: 10, name: 'Seconda settimana', startDate: '9/01/2023', endDate: '15/01/2023', status: 'magnifica' },
  { id: 11, name: 'Altra settimana della Madonna', startDate: '2/01/2023', endDate: '8/01/2023', status: 'bellissima' },
  { id: 12, name: 'Seconda settimana', startDate: '9/01/2023', endDate: '15/01/2023', status: 'magnifica' },
  { id: 13, name: 'Terza settimana', startDate: '16/01/2023', endDate: '22/01/2023', status: 'assurda' },
];


const [formVisible, setFormVisible] = useState(false);



  return (
    <div>
        <h3>Weeks</h3>
        <div className="toolbar">
            <button className="primaryBtn" onClick={() => setFormVisible(true)}>New Week</button>
        </div>

        <DataGrid className="dataGrid"
            autoHeight
            rows={rows}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            paginationMode="server"
        />

        <div className={`overlay ${!formVisible? "hidden" : ''}`}>
            <div className="wrapper">
                <div className="form">
                    <h3>Add new Week</h3>
                    <div>Work in progress</div>
                    <div>
                        <button className="primaryBtn" onClick={() => setFormVisible(false)}>
                        Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );

}