import { 
    CognitoUserPool,
    CognitoUser,
    AuthenticationDetails
} from 'amazon-cognito-identity-js';

import { Logger } from '../../utils/logger';
const logger = new Logger('AuthDataSource');

const { USER_POOL_ID, CLIENT_ID } = require('../../env-vars'); // move this to env variables for real

class AuthenticationDataSource {

    constructor (UserPoolId, ClientId) {
        
        this.userPool = new CognitoUserPool({UserPoolId, ClientId});

        this.cognitoUser = null;

        this._session = null;
        this._idJwt = null;
        this._payload = null;

        this.subscribers = [];
    }

    set session (_session) {
        if (!_session && !this.session ||
            _session && this.session && 
                _session.idToken.jwtToken === this.idJwt) {
                    return;
                }

        if (!_session) {
            this._session = null;
            this._idJwt = null;
            this._payload = null;
        } else {
            this._session = _session;
            this._idJwt = _session.idToken.jwtToken;
            this._payload = {
                ..._session.idToken.payload
            };
        }

        console.log('calling all cb')
        console.log(this.subscribers.length)
        this.subscribers.map(cb => cb(this._idJwt, this._payload, this._session));
    } 

    subscribe (cb) {
        console.log('adding subscriber')
        this.subscribers.push(cb);
        cb(this._idJwt, this._payload, this._session);
        this.getSession();
    }

    getSession () {
        return new Promise((resolve, reject) => {
            this.cognitoUser = this.userPool.getCurrentUser();
            if (!this.cognitoUser) {
                logger.log('Current user not set.');
                return reject(null);
            }
            this.cognitoUser.getSession((error, result) => {
                if (error) {
                    logger.error(error);
                    return reject(error);
                }
                this.session = result;
                return resolve(this._session);
            });
        });
    }

    logIn (email, password) {
        return new Promise((resolve, reject) => {
            this.cognitoUser = new CognitoUser({
                Username: email,
                Pool: this.userPool,
            });
        
            this.cognitoUser.authenticateUser(
                new AuthenticationDetails({
                    Username: email,
                    Password: password,
                }), 
                {
                    onSuccess: (res) => {
                        logger.log('Login success', res);
                        this.session = res;
                        resolve(res);
                    },
                    onFailure: (err) => {
                        logger.log('Login failure', err);
                        reject(err);
                    },
                    newPasswordRequired: (data) => {
                        logger.log('New password required', data);
                        reject(data);  // to be reviewed
                    },
                }
            );
        });
    }
        
    logOut () {
        return new Promise((resolve, reject) => {
            this.cognitoUser.signOut();
            this.session = null;
            resolve(false);
        });
    }
     
}

export const AuthDataSource = new AuthenticationDataSource(USER_POOL_ID, CLIENT_ID);