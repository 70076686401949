import { UsersRepository } from "../../../data/repositories/UsersRepository";

export const ApproveUserImage = async (userId, newImageUrl) => {
    return await UsersRepository.updateUser(userId, {
        newImage: null,
        image: newImageUrl
    });
}

//(Ame)
export const ApproveProfilePicture = async (userId, imageUrl) => {
    return await UsersRepository.approveProfilePicture(userId, {
        image: imageUrl
    });
}