import { UsersRepository } from "../../data/repositories/UsersRepository";
import { GetUsers } from "./GetUsersUC";


export const GetUsersWithRelationships = async () => {
    console.log('getting users and relationships');
    const users = await GetUsers();
    for (let u of users) {
        const rel = await UsersRepository.getUserRelationships(u.id);
        u.relationships = rel;
    }
    return users;
}