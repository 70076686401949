import React, { useEffect, useState } from "react";
import '../../styles/gamesPage.scss'
import { GamesSection } from "./GamesSection";
import { WeeksSection } from "./WeeksSection";

export function GamesPage() {

  const [page, setPage] = useState("weeks");

  function getSelectedSection() {
    switch(page)
    {
      case "weeks" :
        return (
          <WeeksSection/>
        )
      case "games" :
        return (
          <GamesSection/>
        )
      default :
        return (
          <WeeksSection/>
        )
    }
  }

  return (
    <div className="gamesPage">
      <div className="games-header">
        <div className={`tab ${page==="weeks"? "selected" : ''}`} onClick={() => setPage("weeks")}>Weeks</div>
        <div className={`tab ${page==="games"? "selected" : ''}`} onClick={() => setPage("games")}>Games</div>
      </div>

      <section>
        {getSelectedSection()}
      </section>

    </div>
  );

}

