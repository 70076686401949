interface IConceptContent {
  type?: string;
  title: string;
  content: string;
  image?: string;
}

export class ConceptContent implements IConceptContent {
  type?: string;
  title: string;
  content: string;
  image?: string;

  constructor(title: string, content: string, type?: string, image?: string) {
    this.title = title;
    this.content = content;
    this.type = type;
    this.image = image;
  }
}
